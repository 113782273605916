<template>
  <div>
    <section class="section-watch grid-watch" id="service-history">
      <!-- LEFT -->
      <div class="watch-content watch-left">
        <router-link :to="{ name: 'MyWatch', params: { watchId: watchId } }" class="jbtn-watch-link">
          <!-- {{ $C.MODELS_INDEX[watch.model] }} -->
          <img id="watch-model-logo" :src="model.assets.logo" alt="" />
        </router-link>
        <div
          @click="showMenu = true"
          id="show-menu"
          key="show"
          class="jbtn jbtn-text show-l"
          v-bind:class="{ 'hide-menu': showMenu }"
        >
          <i class="fal fa-chevron-down"></i> Show Menu
        </div>
        <div
          @click="showMenu = false"
          id="hide-menu"
          key="hide"
          class="jbtn jbtn-text"
          v-bind:class="{ collapse: !showMenu }"
        >
          <i class="fal fa-chevron-up"></i> Hide Menu
        </div>
        <div class="watch-menu" v-bind:class="{ 'hide-l': true, 'show-menu': showMenu }">
          <router-link :to="{ name: 'Certificate', params: { id: watchId } }" class="jbtn jbtn-watch-nav">
            Info
          </router-link>
          <router-link
            :to="{ name: 'ServicesList', params: { watchId: watchId } }"
            class="jbtn jbtn-watch-nav"
          >
            Servicing
          </router-link>
          <router-link
            :to="{ name: 'ServiceHistory', params: { id: watchId } }"
            class="jbtn jbtn-watch-nav active"
            >Service History</router-link
          >
          <!-- <router-link :to=" { name: 'PaymentsWatch', params: { id: watchId } }" class="jbtn jbtn-watch-nav">Payments</router-link>
        <router-link :to=" { name: 'Tutorials', params: { id: watchId } }" class="jbtn jbtn-watch-nav">Tutorials</router-link> -->
          <router-link :to="{ name: 'Warranty', params: { id: watchId } }" class="jbtn jbtn-watch-nav"
            >Warranty</router-link
          >

          <div v-if="watch.serial" class="mywatch-content jbtn-watch-link watch-right-serial">
            <small>SERIAL</small><br />
            {{ watch.serial }}
          </div>
        </div>
      </div>

      <!-- RIGHT -->
      <div class="watch-content watch-right">
        <div class="jcard-dark jcard-watch-info">
          <div class="jcard-title">
            <h1>Service History</h1>
          </div>

          <!-- services list -->
          <div v-if="appointmentsList.length" key="list">
            <div v-for="appointment in appointmentsList" :key="appointment.id">
              <!-- grid item -->
              <div class="warranty-grid">
                <div class="service-history-title-left">
                  <h2 v-if="appointment.serviceName">{{ appointment.serviceName }}</h2>
                </div>
                <div>
                  <div class="jcard-info-item">
                    <p class="p1-platform p-grey">Service Date</p>
                    <p class="p1-platform">
                      {{ $moment($C.getDateFromTimestamp(appointment.date)).format("MMM YYYY") }}
                    </p>
                  </div>
                </div>
                <div>
                  <div class="jcard-info-item">
                    <p class="p1-platform p-grey">Status</p>
                    <p class="p1-platform">
                      {{ $C.STATUS.APPOINTMENT_STATUS_INDEX[appointment.status] }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- line -->
              <div class="line"></div>
            </div>
          </div>
          <!--------------------- empty --------------------->
          <div v-else key="empty" class="section-empty">
            <h5 class="mb-3"><i class="fad fa-empty-set fa-2x"></i><br /></h5>
            <p class="p-text-s">
              This list is empty at the moment.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import toast from "@/assets/js/toast";
import firebase from "@/firebase/firebaseInit";
import C from "@/const";
const db = firebase.db;
const auth = firebase.auth;

export default {
  name: "MyWatch",
  data() {
    return {
      showMenu: false,
      isLoading: false,
      userId: "",
      watchId: "",
      modelId: "",
      model: {
        id: "",
        name: "First Edition",
        assets: {
          front: "",
          logo: ""
        }
      },

      //lists
      appointmentsList: [],
      servicesMap: new Map()
    };
  },
  methods: {
    getWatch: function(watchId) {
      db.collection("watches")
        .doc(watchId)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            const docdata = doc.data();
            docdata.id = doc.id;
            this.watches.push(docdata);

            //load watch model
            this.modelId = doc.data().modelId;
            this.getWatchModel();
          });
        })
        .catch(error => {
          console.log("Error getting watch: ", error);
        });
    },
    // LOAD watch model
    getWatchModel: function() {
      db.collection(C.COLLECTION.WATCH_CATALOG)
        .doc(this.modelId)
        .get()
        .then(doc => {
          const docdata = doc.data();
          docdata.id = doc.id;
          this.model = docdata;
        })
        .catch(error => {
          console.log("Error getting WATCH MODEL document: ", error);
        });
    },
    // LOAD services completed
    allAppointments: function() {
      this.isLoading = true;
      var appointmentsRef = db
        .collection(C.COLLECTION.APPOINTMENTS)
        .where("userId", "==", auth.currentUser.uid)
        .where("paid", "==", true);
      appointmentsRef = appointmentsRef.where("watchId", "==", this.watchId);
      appointmentsRef = appointmentsRef.where("status", "==", C.STATUS.APPOINTMENT_STATUS.COMPLETED);

      appointmentsRef.orderBy("createdOn", "desc").onSnapshot(querySnapshot => {
        this.appointmentsList.splice(0);
        querySnapshot.forEach(appointmentDoc => {
          const appointment = appointmentDoc.data();
          appointment.id = appointmentDoc.id;
          this.appointmentsList.push(appointment);
        });
        console.log("APPOINTMENTS LOADED:");
        console.log(this.appointmentsList);
        this.isLoading = false;
      });
    }
  },
  computed: {
    user: {
      get() {
        return this.$store.getters.getUser;
      }
    },
    watch: {
      get() {
        return this.$store.getters.getWatch;
      }
    }
  },
  created() {
    //init
    this.userId = auth.currentUser.uid;

    //get id from params
    if (this.$route.params.id != null && this.$route.params.id != "") {
      this.watchId = this.$route.params.id;
    }

    //load watch
    var watchRef = db.collection("watches").doc(this.watchId);
    watchRef.onSnapshot(doc => {
      this.$store.commit("setWatch", doc.data());
      //   this.userId = doc.data().userId;

      //load watch model
      this.modelId = doc.data().modelId;
      this.getWatchModel();
    });

    // load appointments
    this.allAppointments();

    //load user
    var userDocRef = db.collection("users").doc(this.userId);
    userDocRef
      .get()
      .then(userDoc => {
        this.$store.commit("setUser", userDoc.data());
      })
      .catch(function(error) {
        console.log("Error getting USER document:", error);
      });
  }
};
</script>

<style scoped></style>
